<template>
    <div>

        <el-main style=" padding-top: 5px;padding-bottom: initial  ">
            <el-form label-width="80px" @submit.native.prevent>
                <el-row>
                    <el-col :lg="24">
                        <div :class='[this.$store.getters.getRunTimeTest ? "warning" : "tip"]'>
                            <el-row>
                                <el-col :lg="24" style="line-height: 27px;">
                                    <strong style=" font-size: 16px; ">{{this.$store.getters.getRunTimeTest
                                        ?'(测试环境,数据定期删除)':''}}当前：凭证一览</strong>
                                </el-col>
                            </el-row>
                        </div>
                    </el-col>
                    <el-col :lg="24">
                        <el-row>
                            <el-col :lg="4" :sm="12" :md="8">
                                <el-date-picker
                                        style=" margin-top: 8px; width: 220px"
                                        v-model="pickerDate"
                                        type="daterange"
                                        size="mini"
                                        :clearable="false"
                                        :picker-options="pickerOptions"
                                        range-separator="至"
                                        start-placeholder="开始日期"
                                        end-placeholder="结束日期"
                                        align="right">
                                </el-date-picker>
                            </el-col>
                            <el-col :sm="12" :md="8">
                                <el-button style=" margin-top: 8px; " type="primary" round @click="searchAllVoucher"
                                           size="mini">搜索
                                </el-button>
                                <el-button style=" margin-top: 8px; " type="primary" round
                                           :disabled="$refs.xGrid == null || $refs.xGrid.getCurrentRecord() == null"
                                           @click="openVoucher($refs.xGrid.getCurrentRecord())" size="mini">修改
                                </el-button>
                                <el-button type="primary" size="mini" @click="$router.back()"
                                           style="margin-bottom: 2px;margin-top: 2px">返回
                                </el-button>
                                <el-button v-if="$_getActionKeyListBoolean(['/voucher/setIsCancel'])"
                                           style=" margin-top: 8px; " type="danger" round
                                           :disabled="this.isBlankVue(this.idList)"
                                           @click="setIsCancel()" size="mini">作废
                                </el-button>
                                <el-checkbox style="padding-left: 7px" v-model="formData.showCancel"
                                             @change="getAllVoucher()">显示作废
                                </el-checkbox>
                                <el-button style=" margin-top: 8px; margin-left: 8px" type="danger" round
                                           :disabled="this.isBlankVue(this.idList)"
                                           @click="posting(true)" size="mini">过账
                                </el-button>
                                <el-button style=" margin-top: 8px; margin-left: 8px" type="danger" round
                                           :disabled="this.isBlankVue(this.idList)"
                                           @click="posting(false)" size="mini">反过账
                                </el-button>
                            </el-col>
                            <el-col :lg="6" :sm="12" :md="8">
                                <el-row>
                                    <el-col class="pull-left" :lg="4">
                                        <vxe-toolbar class="pull-left" style=" height: 42px; " custom print export
                                                     ref="xToolbar2">
                                        </vxe-toolbar>
                                    </el-col>

                                </el-row>
                            </el-col>
                        </el-row>
                    </el-col>

                </el-row>
                <el-row>
                    <el-col :lg="24">
                        <vxe-grid
                                border
                                resizable
                                show-overflow
                                keep-source
                                ref="xGrid"
                                id="xGrid"
                                align="right"
                                size="mini"
                                :height="(this.getViewHeight() - 130)+''"
                                :print-config="{}"
                                show-footer
                                highlight-current-row
                                :custom-config="{storage:true}"
                                :cell-class-name="cellClassName"
                                @checkbox-all="setIdList"
                                @cell-click="cellCheckboxRowEvent"
                                :row-class-name="$_tableRowClassNameSuccess"
                                :footer-method="footerMethod"
                                :data="tableDataMain"
                                :columns="tableColumn"
                                :merge-cells="mergeCells"
                                :checkbox-config="{range:true}"
                                :mouse-config="{selected: true}"
                                :edit-config="{showIcon:true,trigger: 'click', mode: 'cell', showStatus: true}"
                                :keyboard-config="{enterToTab:true,isArrow: true, isDel: false, isEnter: true, isTab: true,isEdit:false}">
                            <template #operate="{row}">
                                <vxe-button icon="el-icon-view" title="查看" circle
                                            @click="openVoucher(row)"></vxe-button>
                            </template>

                            <template #pager>
                                <vxe-pager
                                        :layouts="['Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total']"
                                        :page-sizes="[1000,2500,5000,50000,500000]"
                                        :current-page.sync="tablePage.currentPage"
                                        :page-size.sync="tablePage.pageSize"
                                        :total="tablePage.total"
                                        @page-change="handlePageChange">
                                </vxe-pager>
                            </template>
                        </vxe-grid>
                    </el-col>
                    <vxe-modal v-model="showModel.isShow" width="600" :title="showModel.bill_id">
                        <template #default>
                            <vxe-grid
                                    ref="modalGrid"
                                    show-overflow
                                    auto-resize
                                    height="300"
                                    size="mini"
                                    highlight-current-row
                                    @cell-dblclick="openVoucher($refs.modalGrid.getCurrentRecord())"
                                    :data="tableDataModel"
                                    :columns="tableColumnModel">
                            </vxe-grid>
                        </template>
                    </vxe-modal>
                </el-row>

            </el-form>
        </el-main>

    </div>

</template>

<script>
    import XEUtils from 'xe-utils'
    import {filterRender} from "@/utils/gird-filter";


    export default {

        data() {
            return {
                idList: '',
                showModel: {
                    bill_id: '',
                    isShow: false,
                },
                //自定义表头
                /*                headerRowStyle:{maxHeight:'80px',height:'80px'},
                                headerCellStyle:{maxHeight:'80px',height:'80px'},
                                showHeaderOverflow:false,*/
                type: '',//空代表默认，CN代表出纳用的（外购单一览）
                formData: {//搜索筛选
                    name: null,
                    showCancel: false,
                },
                tablePage: {
                    total: 0,
                    currentPage: 1,
                    pageSize: 500,
                },
                pickerOptions: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近一年',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '本月',
                        onClick(picker) {
                            var now = new Date();
                            var year = now.getFullYear();
                            var month = now.getMonth() + 1;
                            if (month < 10) {
                                month = '0' + month;
                            }
                            // 本月第一天
                            var startDate = new Date(year + '-' + month + '-01 00:00:00');
                            picker.$emit('pick', [startDate, now]);
                        }
                    }, {
                        text: '上个月',
                        onClick(picker) {
                            var now = new Date();
                            var year = now.getFullYear();
                            var month = now.getMonth();
                            if (month == 0) {
                                month = 12;
                                year = year - 1;
                            }
                            if (month < 10) {
                                month = '0' + month;
                            }
                            var myDate = new Date(year, month, 0);
                            // 上个月第一天
                            var startDate = new Date(year + '-' + month + '-01 00:00:00');
                            // 上个月最后一天
                            var endDate = new Date(year + '-' + month + '-' + myDate.getDate() + ' 23:59:00');
                            picker.$emit('pick', [startDate, endDate]);
                        }
                    }]
                },
                mergeCells: [],
                tableColumn: [
                    {type: 'checkbox', title: '', field: 'id', width: 50},
                    {title: '凭证总号',field: 'id', width: 80, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allVoucher/searchHeard', this.pickerDate, this.formData)
                        }
                    },
                    {
                        field: 'is_cancel_name', width: 60, title: '状态', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allVoucher/searchHeard', this.pickerDate, this.formData)
                        }
                    },
                    {
                        field: 'is_posting', width: 60, title: '过账', formatter: ({cellValue}) => {
                            if (cellValue) {
                                return '✔';
                            }
                        }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allVoucher/searchHeard', this.pickerDate, this.formData)
                        }
                    },
                    {
                        field: 'is_posting_man', width: 70, title: '过账人', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allVoucher/searchHeard', this.pickerDate, this.formData)
                        }
                    },
                    {
                        field: 'bill_type', width: 90, title: '单据类型', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allVoucher/searchHeard', this.pickerDate, this.formData)
                        }
                    },
                    {
                        field: 'create_type', width: 80, title: '创建类型', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allVoucher/searchHeard', this.pickerDate, this.formData)
                        }
                    }, {
                        field: 'voucher_date', width: 100, title: '凭证日期', formatter: ({cellValue}) => {
                            return this.formatDate(cellValue, 'yyyy-MM-dd')
                        }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allVoucher/searchHeard', this.pickerDate, this.formData)
                        }
                    },
                    {
                        field: 'type_id', width: 70, title: '凭证字', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allVoucher/searchHeard', this.pickerDate, this.formData)
                        }
                    }, {
                        field: 'summary', width: 180, title: '摘要', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allVoucher/searchHeard', this.pickerDate, this.formData)
                        }
                    }, {
                        field: 'subject_id', width: 80, title: '科目编码', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allVoucher/searchHeard', this.pickerDate, this.formData)
                        }
                    }, {
                        field: 'subject_name', width: 160, title: '科目名称', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allVoucher/searchHeard', this.pickerDate, this.formData)
                        }
                    }, {
                        field: 'company_name_assist', width: 220, title: '辅助核算', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allVoucher/searchHeard', this.pickerDate, this.formData)
                        }
                    }, {
                        field: 'borrow_money', width: 80, title: '借方金额', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allVoucher/searchHeard', this.pickerDate, this.formData)
                        }
                    }, {
                        field: 'lent_money', width: 80, title: '贷方金额', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allVoucher/searchHeard', this.pickerDate, this.formData)
                        }
                    }, {
                        field: 'borrow_money_total', width: 80, title: '合计金额', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allVoucher/searchHeard', this.pickerDate, this.formData)
                        }
                    },
                    {
                        field: 'registrar', width: 70, title: '记录员', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allVoucher/searchHeard', this.pickerDate, this.formData)
                        }
                    }, {
                        field: 'original_bills', width: 120, title: '附原始单据', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allVoucher/searchHeard', this.pickerDate, this.formData)
                        }
                    }, {
                        field: 'last_name', width: 90, title: '最后修改人', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allVoucher/searchHeard', this.pickerDate, this.formData)
                        }
                    }, {
                        field: 'last_date', width: 120, title: '最后修改日期', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allVoucher/searchHeard', this.pickerDate, this.formData)
                        }
                    }, {
                        field: 'create_date', width: 120, title: '创建日期', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allVoucher/searchHeard', this.pickerDate, this.formData)
                        }
                    }, {
                        field: 'system_remarks', width: 120, title: '备注', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allVoucher/searchHeard', this.pickerDate, this.formData)
                        }
                    }, {
                        field: 'import_remarks', width: 120, title: '系统备注', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allVoucher/searchHeard', this.pickerDate, this.formData)
                        }
                    }, {
                        field: 'cash_data_remark', width: 120, title: '出纳单备注', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allVoucher/searchHeard', this.pickerDate, this.formData)
                        }
                    }, {
                        field: 'only_bill_id_voucher',
                        width: 120,
                        title: '对应单号', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allVoucher/searchHeard', this.pickerDate, this.formData)
                        }
                    },
                ],
                tableDataMain: [],
                tableDataModel: [],
                tableColumnModel: [
                    {field: 'cash_type_id', title: '单据编码', width: 100},
                    {field: 'cash_type_name', width: 122, title: '单据类型'},
                    {field: 'year', width: 40, title: '年'},
                    {field: 'month', width: 40, title: '月'},
                    {field: 'amount', width: 150, title: '金额'},
                    {field: 'registrar_cash_data', width: 150, title: '创建人'},
                    {field: 'creat_date_cash_data', width: 150, title: '创建日期'},
                    {field: 'last_name_cash_data', width: 150, title: '最后修改人'},
                    {field: 'last_date_cash_data', width: 150, title: '最后修改日期'},
                ],
                filterData: {},
                sortData: {"type_id": "asc"},
                pickerDate: [new Date(new Date().getTime() - 3600 * 1000 * 24 * 90), new Date()],
                activeName: 'first',

            }
        }, computed: {},
        methods: {
            openVoucher(row) {
                const {href} = this.$router.resolve({path: '/voucher', query: {id: row.id}})
                window.open(href, '_blank');
            },
            findCDWBListByBillId() {
                this.$axios({
                    method: 'post',
                    url: '/admin/allVoucher/findCDWBListByBillId',
                    params: {
                        bill_id: this.showModel.billId
                    }
                }).then((response) => {         //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    if (response.status === 200) {
                        this.tableDataModel = response.data.list;
                    }
                }).catch((error) => {
                    console.log(error)
                });
            },
            cellClassName({row, column}) {
                if (column.property === 'cn_state_man') {
                    if (row.cn_state_man) {
                        return 'col-success'
                    } else {
                        return 'col-fail'
                    }
                } else if (column.property === 'cash_type_id_list') {
                    return 'col-ableClick'
                }
            },
            cellCheckboxRowEvent({row, rowIndex, column}) {
                //console.log(row, rowIndex,column)
                //console.log(row)
                if (column.property != 'id') {
                    if (this.$refs.xGrid.isCheckedByCheckboxRow(row)) {
                        this.$refs.xGrid.setCheckboxRow([this.tableDataMain[rowIndex]], false)
                    } else {
                        this.$refs.xGrid.setCheckboxRow([this.tableDataMain[rowIndex]], true)
                    }
                }

                this.$refs.xGrid.clearSelected();
                this.setIdList()
            },

            setIdList() {
                let selectRecords = this.$refs.xGrid.getCheckboxRecords();//vue子组件调用父组件ref
                this.idList = ''
                selectRecords.forEach(item => {
                    this.idList = item.id + ',' + this.idList;
                });
            },

            searchAllVoucher() {
                this.tablePage.currentPage = 1;
                this.getAllVoucher();
            },
            getAllVoucher() {
                const loading = this.$loading({
                    lock: true,
                    text: '饮茶先啦，数据查询中，请耐心等待!',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                let searchUrl = '/admin/allVoucher/search'
                this.$axios({
                    method: 'POST',
                    url: searchUrl,
                    data: {
                        currentPage: this.tablePage.currentPage,
                        pageSize: this.tablePage.pageSize,
                        sortData: this.sortData,
                        filterData: this.filterData,
                        pickerDate: this.pickerDate,
                        formData: this.formData
                    }
                }).then((response) => {         //这里使用了ES6的语法
                    loading.close();
                    //console.log(response)       //请求成功返回的数据
                    if (response.status === 200) {
                        this.tableDataMain = response.data.page.list
                        this.tablePage.pageSize = response.data.page.pageSize
                        this.tablePage.total = response.data.page.totalRow
                        this.tablePage.currentPage = response.data.page.pageNumber
                        this.mergeCells = response.data.mergeCells
                    }
                }).catch((error) => {
                    loading.close();
                    console.log(error)
                });
            },
            handlePageChange({currentPage, pageSize}) {
                this.tablePage.currentPage = currentPage
                this.tablePage.pageSize = pageSize
                this.getAllVoucher();
            },
            getNumberDefaultZero(obj) {
                //console.log("getNumberDefaultZero : " + obj);
                if (obj == null || typeof (obj) == 'undefined' || isNaN(obj)) {
                    return Number(0)
                } else {
                    return Number(obj)
                }
            },
            posting(isPosting) {
                let msg = '您确定要过账/反过账：' + this.idList + '单号?';
                //console.log('this.idList.length: ' + this.idList.length)
                //console.log('this.idList.substr(0,20): ' + this.idList.substr(0,20))
                if (this.idList.length > 20) {
                    msg = '您确定要过账/反过账：' + this.idList.substr(0, 20) + '...等等单号?';
                }
                this.$XModal.confirm(msg).then(type => {
                    //const $table = this.$refs.xTable
                    if (type === 'confirm') {
                        const loading = this.$loading({
                            lock: true,
                            text: '饮茶先啦，数据处理中，请耐心等待!',
                            spinner: 'el-icon-loading',
                            background: 'rgba(0, 0, 0, 0.7)'
                        });
                        //传送删除动作
                        this.$axios({
                            method: 'post',
                            url: '/admin/voucher/posting',
                            data: {
                                id: this.idList,
                                isPosting: isPosting
                            }
                        }).then((response) => {         //这里使用了ES6的语法
                            loading.close();
                            //console.log(response)       //请求成功返回的数据
                            if (response.status === 200 && response.data.state == 'ok') {
                                this.$message({
                                    showClose: true,
                                    message: response.data.msg,
                                    type: 'success'
                                });
                                this.getAllVoucher();
                            } else {
                                this.$message({
                                    showClose: true,
                                    message: response.data.msg,
                                    type: 'error'
                                });
                                return
                            }
                        }).catch((error) => {
                            loading.close();
                            console.log(error)
                        });
                    }
                })
            },
            setIsCancel() {
                //console.log(this.$refs.xGrid.getCheckboxRecords());
                //console.log('selectRecords', selectRecords);
                let msg = '您确定要作废：' + this.idList + '单号?作废后不能修改，不能反作废。';
                //console.log('this.idList.length: ' + this.idList.length)
                //console.log('this.idList.substr(0,20): ' + this.idList.substr(0,20))
                if (this.idList.length > 20) {
                    msg = '您确定要作废：' + this.idList.substr(0, 20) + '...等等单号?作废后不能修改，不能反作废。';
                }
                this.$XModal.confirm(msg).then(type => {
                    //const $table = this.$refs.xTable
                    if (type === 'confirm') {
                        const loading = this.$loading({
                            lock: true,
                            text: '饮茶先啦，数据处理中，请耐心等待!',
                            spinner: 'el-icon-loading',
                            background: 'rgba(0, 0, 0, 0.7)'
                        });
                        //传送删除动作
                        this.$axios({
                            method: 'post',
                            url: '/admin/voucher/setIsCancel',
                            data: {
                                id: this.idList
                            }
                        }).then((response) => {         //这里使用了ES6的语法
                            loading.close();
                            //console.log(response)       //请求成功返回的数据
                            if (response.status === 200 && response.data.state == 'ok') {
                                this.$message({
                                    showClose: true,
                                    message: response.data.msg,
                                    type: 'success'
                                });
                                this.getAllVoucher();
                            } else {
                                this.$message({
                                    showClose: true,
                                    message: response.data.msg,
                                    type: 'error'
                                });
                                return
                            }
                        }).catch((error) => {
                            loading.close();
                            console.log(error)
                        });
                    }
                })
            },
            sumNum(list, field) {
                let count = Number(0)
                list.forEach(item => {
                    count = this.$XEUtils.add(count, item[field]);
                })
                return this.$XEUtils.round(this.getNumberDefaultZero(count), 2)
            },
            footerMethod({columns, data}) {
                const sums = []
                columns.forEach((column, columnIndex) => {
                    if (columnIndex === 0) {
                        sums.push('合计')
                    } else {
                        if (column.property === 'amount_of_product' || column.property === 'total_number'
                            || column.property === 'total_weighing_heavy' || column.property === 'additional_charges'
                            || column.property === 'total_amount_of_product' || column.property === 'total_amount'
                            || column.property === 'borrow_money' || column.property === 'lent_money'
                            || column.property === 'payend' || column.property === 'balance') {
                            sums.push(this.sumNum(data, column.property))
                        } else {
                            sums.push('')
                        }
                    }
                })
                //this.autoComputed();
                // 返回一个二维数组的表尾合计
                return [sums]
            },
            formatDate(value, format) {
                return XEUtils.toDateString(value, format)
            },
        },
        created() {
            if (!this.isBlankVue(this.$route.query.type)) {
                this.type = this.$route.query.type;
            }
            if (!this.isBlankVue(this.$route.query.subject_id)) {
                this.filterData.subject_id = this.$route.query.subject_id;
                this.pickerDate[0] = this.$route.query.startDate
                this.pickerDate[1] = this.$route.query.endDate
            }

            this.getAllVoucher();
            this.$nextTick(() => {
                // 手动将表格和工具栏进行关联
                this.$refs.xGrid.connect(this.$refs.xToolbar2)
            })
        }
    };


</script>

<style scoped>
    .header-wrapepr {
        display: flex;
        flex-direction: row;
        justcontent: space-between
    }

    .el-date-editor.el-input, .el-date-editor.el-input__inner {
        width: 130px;
    }

    .el-form-item__content .el-input {
        width: 130px;
    }

    /deep/ .el-input--suffix .el-input__inner {
        padding-right: initial !important;
    }

    /deep/ input[popperclass="myAutocomplete"] {
        padding: 3px !important;
    }

    /deep/ .el-input__suffix {
        right: 0px !important;
    }
</style>
